import cn from "classnames"
import { graphql } from "gatsby"
import { getImage, IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layouts/layout/layout"
import Button from "../components/freestanding/button/button"
import Container from "../components/freestanding/containers/container"
import Grid from "../components/freestanding/containers/grid"
import YoutubeEmbed from "../components/layouts/embed/embed"
import DiversityStatement from "../components/layouts/summit/diversity-statement"
import { verticalNav as verticalNavSummit21 } from "../page-content/events/content-summit-21"
import * as content21 from "../page-content/events/content-summit-21"
import * as content22 from "../page-content/events/content-summit-22"
import { verticalNav as verticalNavSummit22 } from "../page-content/events/content-summit-22"
import {
  pb16,
  pb32,
  pb64,
  pb8,
  pt32,
} from "../components/freestanding/utils/padding.module.css"
import * as styles from "../components/layouts/summit/session-list.module.css"

interface Props {
  path: string
  pageContext: {
    year: number
  }
  data: {
    session: {
      frontmatter: {
        seo: {
          title: string
          description: string
          keywords: string
        }
        order: string
        title: string
        description: string
        speakers: string[]
        slug: string
        youtubelink: string
        thumbnail: {
          childImageSharp: IGatsbyImageData
        }
        presentationlink: string
        publishedAt: string
      }
    }
    speakers: {
      nodes: {
        frontmatter: Speaker
      }[]
    }
  }
}

export interface Speaker {
  id: string
  fullname: string
  speakerImage: {
    childImageSharp: IGatsbyImageData
  }
  social: string
  title: string
  company: string
  about: string
}

export interface Session {
  seo: {
    title: string
    description: string
  }
  order: number
  title: string
  description: string
  ready: string
  banner: {
    childImageSharp: IGatsbyImageData
  }
  speakers: string[]
  slug: string
}

export default function SummitTemplate(props: Props) {
  const data = props.data.session.frontmatter
  const speakers = props.data.speakers.nodes

  const year = props.pageContext.year
  const theme = year == 2021 ? "info" : "summit-22"
  const verticalNav = year == 2021 ? verticalNavSummit21 : verticalNavSummit22
  const diversityContent =
    year == 2021 ? content21.diversityStatement : content22.diversityStatement

  const youtubeId =
    new URL(data.youtubelink).searchParams.get("v") || "yaMRzF9arDc"

  return (
    <Layout theme={theme} summit={{ verticalNav: verticalNav, year: year }}>
      <Container className={cn(styles.sessionList)} alignItems={"start"}>
        <h1 className={cn(pb64)}>{data.title}</h1>
        <YoutubeEmbed
          embedId={youtubeId}
          thumbnail={getImage(data.thumbnail.childImageSharp)}
        />
        <p className={cn(pb32)}>{data.description}</p>
        <Button
          style={"filled"}
          to={data.presentationlink}
          openInNewWindow={true}
        >
          Download the Presentation
        </Button>
      </Container>
      <hr className={styles.divider} aria-hidden="true" />

      <Container flexContainer={"column"} alignItems={"stretch"}>
        {speakers.map(({ frontmatter }) => {
          const speakerImage = getImage(
            frontmatter.speakerImage.childImageSharp,
          )

          return (
            <Grid lg={12} md={12} sm={12} xs={12} key={frontmatter.fullname}>
              <article className={styles.speakerCardLarge}>
                <Container className={styles.speakerCardDirection}>
                  <Button
                    to={frontmatter.social}
                    openInNewWindow={true}
                    style="none"
                    className={styles.speakerInfo}
                  >
                    <article>
                      <Container
                        key={frontmatter.social}
                        className={cn(styles.speakerSocial, pb16)}
                      >
                        <Container
                          flexContainer={"column"}
                          justify={"center"}
                          alignItems={"center"}
                        >
                          {speakerImage ? (
                            <GatsbyImage
                              image={speakerImage}
                              alt={frontmatter.fullname}
                              className="responsive"
                            />
                          ) : null}
                          <Container
                            flexContainer={"column"}
                            alignItems={"center"}
                            className={cn("text-is-centered", pt32)}
                          >
                            <h6 className={cn(pb8)}>{frontmatter.fullname}</h6>
                            <p
                              className={cn("font-p-small", styles.speakerName)}
                            >
                              {frontmatter.title} at {frontmatter.company}
                            </p>
                          </Container>
                        </Container>
                      </Container>
                    </article>
                  </Button>
                  <Container className={styles.speakerAbout}>
                    <p>{frontmatter.about}</p>
                  </Container>
                </Container>
              </article>
            </Grid>
          )
        })}
      </Container>

      <hr className={styles.divider} aria-hidden="true" />
      <DiversityStatement {...diversityContent} />
    </Layout>
  )
}

export const Head = ({ data }: Props) => {
  const session = data.session.frontmatter
  const seo = data.session.frontmatter.seo
  return (
    <>
      <title>{seo?.title || data.session.frontmatter.title}</title>
      <meta
        name="description"
        content={seo?.description || data.session.frontmatter.description}
      />
      <meta
        property="og:title"
        content={seo?.title || data.session.frontmatter.title}
      />
      <meta
        property="og:description"
        content={seo?.description || data.session.frontmatter.description}
      />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="Ory" />
      <meta
        property="twitter:title"
        content={seo?.title || data.session.frontmatter.title}
      />
      <meta
        property="twitter:description"
        content={seo?.description || data.session.frontmatter.description}
      />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "VideoObject",
          name: session.title,
          description: session.description,
          thumbnailUrl: [
            getImage(session.thumbnail.childImageSharp)?.images.fallback?.src
              ? getImage(session.thumbnail.childImageSharp)!.images.fallback!
                  .src
              : "",
          ],
          uploadDate: session.publishedAt,
          embedUrl:
            "https://www.youtube-nocookie.com/embed/" +
              new URL(session.youtubelink).searchParams.get("v") ||
            "yaMRzF9arDc",
        })}
      </script>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String!, $speakers: [String]) {
    session: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        order
        title
        description
        speakers
        slug
        youtubelink
        thumbnail {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        presentationlink
        publishedAt
      }
    }
    speakers: allMdx(
      filter: {
        frontmatter: { type: { eq: "summit-speaker" }, id: { in: $speakers } }
      }
    ) {
      nodes {
        frontmatter {
          id
          fullname
          speakerImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          social
          title
          company
          about
        }
      }
    }
  }
`
